import React, { useMemo } from 'react';

import { LocaleProvider, useLocale, countries } from '@vp/digital-locale-lib';
import {
  RuntimeContextProvider,
  useRuntimeContext,
  Environment,
  AccessType,
} from '@vp/digital-environment-lib';
import Redirect from './Redirect';
import { ContextModel } from './models';
import { invariant } from '@vp/digital-invariant-lib';

// Examples:
// multi:  https://cartui.cdn.orders.vpsvc.com/VP-CA-EN-STAGING/en-CA/cart
// single: https://cartui.cdn.orders.vpsvc.com/VP-DE-STAGING/de-DE/cart

const useCartUrl = (): string => {
  const { environment, accessType } = useRuntimeContext();
  invariant(accessType === AccessType.None || accessType === AccessType.Direct);

  const { identifier: locale, countryCode, languageCode } = useLocale();
  const { multiLanguage } = countries[countryCode];

  const cartUrl = useMemo<string>(() => {
    const CART_ORIGIN = 'https://cartui.cdn.orders.vpsvc.com';
    let temp: string = `${CART_ORIGIN}/VP-${countryCode}`;
    if (multiLanguage) {
      temp += `-${languageCode.toUpperCase()}`;
    }
    if (environment !== Environment.Prod) {
      temp += '-STAGING';
    }
    temp += `/${locale}`;
    temp += '/cart';
    return temp;
  }, [countryCode, environment, languageCode, locale, multiLanguage]);

  return cartUrl;
};

const CartRedirect = ({ mfeName }: { mfeName: string }) => {
  const cartUrl = useCartUrl();
  return (
    <div>
      <p>Welcome to the {mfeName} cart redirect page</p>
      <p>
        You should be redirected to the <a href={cartUrl}>cart</a> shortly.
      </p>
      <Redirect redirectPath={cartUrl} isRedirecting={true} />
    </div>
  );
};

interface CartRedirectPageProps {
  pageContext: ContextModel;
}

// grabs the last locale from cookie
const CartRedirectPage = ({
  pageContext: { mfeName, getEnvironment },
}: CartRedirectPageProps) => {
  return (
    <LocaleProvider allowDefault={true}>
      <RuntimeContextProvider getEnvironment={getEnvironment}>
        <CartRedirect mfeName={mfeName} />
      </RuntimeContextProvider>
    </LocaleProvider>
  );
};

export default CartRedirectPage;
